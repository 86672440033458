$label-width: 70px;

#header {
    .blockcart {
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;
        cursor: pointer;
        @include media-breakpoint-up(sm) {
            position: relative;
        }
        .show-link {
            color: $gray-dark-color;
            text-transform: uppercase;
            position: relative;
            display: none;
            @include media-breakpoint-up(sm) {
                display: block;
            }
        }
        .show-link-mobile {
            color: $gray-dark-color;
            text-transform: uppercase;
            position: relative;
            display: block;
            @include media-breakpoint-up(sm) {
                display: none;
            }
        }
        .subtotal-amount {
            width: 1.6rem;
            height: 1.6rem;
            border-radius: 50%;
            z-index: 100;
            font-size: $font-size-small-title;
            background: $white;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            right: -0.8rem;
            top: -0.8rem;
            border: 1px #999 solid;
        }
    }
    .shoppingcart-popover-header {
        //        color:$gray-dark-color !important;
        padding: 1rem;
        display: block;
        background: $red;
        font-weight: 400;
    }
    .shoppingcart-products {
        width: 100%;
        border-color: $gray-light-color;
        a {
            color: inherit;
        }
        .product-attributes {
            font-size: $font-size-small;
        }
        .product-price {
            text-align: right;
            padding-left: .5rem;
            padding-right: .5rem;
        }
        .label {
            text-align: right;
            padding-right: 1rem;
            font-weight: 400;
        }
        .value {
            font-weight: 400;
            text-align: right;
        }
    }
    #shoppingcart_preview {
        background: $white;
        font-size: $font-size-small-title;
        left: 0;
        right: 0;
        position: absolute;
        min-width: 0;
        z-index: 99;
        @include media-breakpoint-up(sm) {
            min-width: 480px;
            right: -60px;
            left: auto;;
        }
    }
}

#blockcart-modal{
    .modal-header {
        border-color: $gray-color;
    }
    .modal-footer {
        border-color: $gray-color;
    }
}
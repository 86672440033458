.progressbar {
    counter-reset: step;
    padding-left: 0;
    margin-top: 2rem;
    margin-top: 1rem;
    li {
        list-style-type: none;
        width: 25%;
        float: left;
        font-size: 12px;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        color: #7d7d7d;
        &:before {
            width: 30px;
            height: 30px;
            content: counter(step);
            counter-increment: step;
            line-height: 30px;
            border: 2px solid #7d7d7d;
            display: block;
            text-align: center;
            margin: 0 auto 10px auto;
            border-radius: 50%;
            background-color: white;
        }
        &:after {
            width: 100%;
            height: 2px;
            content: '';
            position: absolute;
            background-color: #7d7d7d;
            top: 15px;
            left: -50%;
            z-index: -1;
        }
        &:first-child:after {
            content: none;
        }
        &.active {
            color: green;
            &:before {
                border-color: #55b776;
            }
            & + li:after {
                background-color: #55b776;
            }
        }
    }
}

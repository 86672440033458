.facet-row {
    border-bottom: 1px solid $gray-color;
    .active-filter-title {
        text-transform: uppercase;
    }
    ul {
        padding-left: 0;
    }
}
#search_filters {
    padding-top: $grid-gutter-width;
    .facet {
        cursor: pointer;
        .facet-title {
            padding-top: .4rem;
        }
        .facets-container {
            position: absolute;
            background: $orange;
            z-index: 5;
            border: 1px solid $gray-color;
            color: $white;
            padding: .5rem;
            min-width: calc(100% - #{2 * $grid-gutter-width};
            li {
                list-style: none;
            }
            a {
                color: inherit;
            }
        }
        .title.collapsed {
            .material-icons.add {
                display: inline-block;
            }
            .material-icons.remove {
                display: none;
            }
        }
        .title:not(.collapsed) {
            .material-icons.add {
                display: none;
            }
            .material-icons.remove {
                display: inline-block;
            }
        }
    }
}

#js-active-search-filters {
    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
    padding-top: $grid-gutter-width;
    .filter-block {
        .close {
            text-shadow: none;
            font-size: 1.2rem;
            line-height: 1.2;
        }
    }
}
.page-cart {
    .container {
        margin-bottom: 2rem;
        a {
            color: inherit;
            text-decoration: none;
        }
    }
    .cart-overview {
        td {
            vertical-align: middle;
        }
        .product-quantity-container {
            display: flex;
            .product-quantity {
                width: 4rem;
                text-align: center;
            }
            .product-quantity-button {
                width: 1.2rem;
                display: block;
                flex: 1 1 auto;
                text-align: center;
                background: $gray-light-color;
                font-weight: 500;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .td-product-image {
            text-align: center;
        }
        .td-product-price {
            text-align: center;
        }
        .td-remove-from-cart {
            text-align: center;
        }
        .td-product-summary-price {
            text-align: right;
        }
    }
    .cart-buttons {
        display: flex;
        justify-content: space-between;
    }

    .cart-detailed-totals {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 2rem;
        table {
            td {
                padding: 0.75rem;
                text-align: right;
                width: 20vw;
                &:first-child {
                    width: 40vw;
                }
                .value {
                    font-weight: 400;
                }
            }
        }
    }
}
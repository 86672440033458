.product-miniature {
    position: relative;
    padding-bottom: $grid-gutter-width / 2;
    padding-top: $grid-gutter-width / 2;
    transition: all 1s;
    display: flex;
    &:hover {
        //   transform: scale(1.05);
        background: tint-color($gray-light-color, 80%);
    }
    .product-mminiature-wrapper {
        border: 1px solid $gray-light-color;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .miniature-product-title {
        margin-top: auto;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        font-size: $font-size-small-title;
        font-weight: 300;
        padding: $grid-gutter-width / 2;
        //        text-transform: uppercase;
        text-align: left;
        text-align: center;
        line-height: 1.2;
        background: $white;
        a {
            color: $gray-dark-color;
            text-decoration: none;
        }
    }
    .product-thumbnail {
        display: block;
        //        margin: 0 50px;
        .card-img-top {

        }
    }
    .product-description-short {
        display: none;
    }
    .variant-links {
        display: none;
    }
    .product-availability {
        display: none;
    }
    .product-mminiature-top {
        margin: 2px 8px 30px;
        display: flex;
        flex-wrap: wrap;
        span {
            display: flex;
            align-items: center;
            line-height: 1;
            flex: 1 1 auto;
            text-align: center;
            align-items: center;
            justify-content: center;
            margin: 2px;
            font-size: $font-size-small-title;
            font-weight: 500;
            padding: 4px;
            &.logo {
                flex: 0 0 auto;
                padding: 0;
            }
            &.sale {
                background: $gray-light-color;
                color: $header-color;
            }
            &.best-sale {
                background: $gray-dark-color;
                color: $white;
            }
        }
    }
    .product-mminiature-bottom {
        background: $header-color;
        margin-top: auto;
        justify-content: space-between;
        //        flex-wrap: wrap;
    }
    .product-price-and-shipping {
        color: $gray-color;
        font-weight: 400;
        text-align: center;
    }
    .product-list-actions {
        display: flex;
        margin-top: 1rem;
        .action-btn {
            width: 100%;
            flex: 0 0 auto;
            text-align: center;
            background: $red;
            color: $white;
            font-size: $font-size-small-title;
            text-decoration: none;
            border-left: 1px solid $white;
            padding: .5rem;
            &:first-child {
                border: none;
            }
        }
        /*        background-image: url(../img/product-miniature-background.svg);
                background-position: center left;
                background-repeat: no-repeat;
                padding: 8px 20px 8px 60px;
                margin-left: -15px;*/
        .add-to-cart {
        }
    }
    .product-flags {
        margin-bottom: 0;
        .discount {
            display: none;
        }
    }
}

.product-miniature-category-list {
    font-size: $font-size-small-title;
    border: 1px solid $gray-color;
    margin-bottom: $grid-gutter-width / 2;
    margin-top: $grid-gutter-width / 2;
    padding: $grid-gutter-width / 2;
    .product-category-description {
        border-right: 1px solid $form-control-border
    }
    .product-miniature-title {
        font-size: $font-size-large;
        a {
            color: inherit;
            text-decoration: none;
        }
    }
    .variant-links {
        display: flex;
        a {
            display: block;
            height: $font-size * 1.5;
            width: $font-size * 1.5;
            margin-right: .25rem;
            border: 1px solid $black;
        }
    }
    .product-price-and-shipping {
        /*text-align: right;*/
        .price {
            font-size: $font-size-large;
            font-weight: 400;
        }
    }
    .product-mminiature-actions {
        display: flex;
        flex-direction: column;
    }
    .product-list-actions {
        margin-top: auto;
    }
    .btn {
        margin-bottom: $grid-gutter-width / 4;
        margin-top: $grid-gutter-width / 4;
        width: 100%;
    }
}

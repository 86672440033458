.htmlblock-content {
    font-size: $font-size-small-title;
    h1 {
        font-size: $font-size-large;
        font-weight: 400;
        padding-bottom: 1rem;
    }
}

#footer {
    .html-block {
        .row {
            align-items: center;
        }
        h3 {
            font-size: $font-size;
            font-weight: 400;
            padding-bottom: 1rem;
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
}
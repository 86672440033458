$vmargins: .5rem;
.page-cms {
    font-size: $font-size-small-title;
    h2 {
        font-size: $font-size-large;
    }
    li {
        margin-top: $vmargins;
        margin-bottom: $vmargins;
    }
}
.page-index {
    #content {
        #jms-html {
            background: $gray-light-color;
            strong {
                font-weight: 500;
                color: $black;
            }
            .jms-content {
                @extend .container;
                .jms-html {
                    .col-md {
                        display: flex;
                        align-items: center;
                        padding: .0 1rem;
                        p {
                            margin-bottom: 0;
                            margin-left: 1rem;
                            line-height: 1.1;
                        }
                    }
                }
            }
        }
    }
}

.tweets.block_right {
    display: flex;
    align-items: center;
    img {
        width: 3rem;
        height: 3rem;
    }
    p {
        margin-bottom: 0;
        margin-left: 1.5rem;
        margin-right: 3rem;
        a {
            color: $body-background-color;
        }
    }
}

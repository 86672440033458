#notifications {
    .notification {
        background: $gray-dark-color;
        color: $white;
        display: flex;
        flex-direction: row-reverse;
        .btn-close {
            margin-left: auto;
        }
        > ul {
            list-style: none;
        }
    }
}
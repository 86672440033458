.page-order-detail {
    .address {
        .inner {
            border: $border;
            padding: $spacer * 2;
            h1 {
                font-size: $font-size;
            }
            p {
                font-size: $font-size-small;
            }
        }
    }
}
.page-my-account {
    .link-list {
        padding: 0;
        li {
            border: 1px solid #ccc;
            margin-bottom: .5rem;
            list-style: none;
            a {
                display: block;
                padding: .5rem 1rem;
            }
        }
    }
}
.my-account-links {
    margin-top: 1rem;
    margin-bottom: 1rem;
    ul {
        @extend .row;
        display: flex;
        list-style: none;
        padding: 0;
        li {
            @extend .col-auto;
        }
    }
}

#header {
    .top-row {
        padding-top: 1rem;
        padding-bottom: .5rem;
        background: $gray-dark-color;
        color: $white;
        a {
            color: $white;
            &.btn-outline-primary {
                color: map-get($theme-colors, "primary");
                &:hover {
                    color: $white;
                }
            }
        }
        p {
            margin: 0;
        }
        .top-right,
        .top-left {
            display: flex;
            align-items: center;
        }
        .top-right {
            border-bottom: 1px solid $gray-color;
        }
        @include media-breakpoint-up(md) {
            .top-right {
                border: none;
            }
        }
        .user-info {
            display: flex;
            align-items: center;
        }
        span.mobile {
            display: block;
            @include media-breakpoint-up(md) {
                display: inline;
            }
        }
    }
    .display-nav {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        @include media-breakpoint-up(md) {
            justify-content: space-between;
        }
    }
}

#search_widget {
    margin-left: auto;
    min-width: 100%;
    flex-grow: 1;
    @include media-breakpoint-up(md) {
        flex-grow: 0;
        min-width: 300px;
    }
}
.ui-autocomplete.ui-widget {
    font-size: $font-size-small-title;
}

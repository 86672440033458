#checkout {
    .js-checkout-summary {
        font-size: $font-size-small-title;
        //        margin-top: .5rem;
        padding: .5rem;
        border: 1px solid $gray-light-color;
        border-radius: $border-radius;
        .checkout-summary-title {
            font-size: $font-size;
        }
    }
    #cart-summary-product-list {
        table {
            margin-left: calc(-.2rem - 6px);
            margin-right: calc(-.2rem - 6px);
        }
        td {
            line-height: 1;
            vertical-align: top;
            padding: .2rem;
            &:last-child {
                text-align: right;
            }
            img {
                max-width: 60px;
                border: 1px solid $gray-light-color;
            }
        }
    }
    .cart-summary-totals {
        border-top: 1px solid $gray-light-color;
        margin-top: .3rem;
        margin-bottom: 1rem;
        padding-top: .2rem;
    }
    .summary-first-line {
        margin-bottom: 0;
    }
    .total-line {
        display: flex;
        justify-content: space-between;
        .value {
            font-weight: 400;
        }
    }
    .checkout-step {
        @include rounded-border;
        margin-bottom: .5rem;
        .step-title {
            font-size: $font-size-large;
            margin-bottom: 0;
            color: $gray-color;
            a {
                color: inherit;
                text-decoration: none;
                color: $gray-dark-color;
                &:before {
                    width: 1.25em;
                    line-height: 0;
                    display: inline-block;
                    // content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
                    transition: transform 0.35s ease;
                    transform-origin: .4em 50%;
                    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
                }
                &.collapsed:before {
                    transform: rotate(180deg);
                }
            }
        }
        .form-control {
            margin-bottom: .5rem;
        }
        label.required {
            &:after {
                content: "*";
            }
        }
        button.continue {
            margin-top: .5rem;
        }
        .show_hide_password {
            .form-control {
                padding-bottom: .5rem;
                margin-bottom: 0;
            }
        }
    }
    .field-register {
        @include rounded-border;
        margin: 1rem 0;
        .field-group-description {
            font-size: 85%;
        }
    }
    .address-selector {
        .address-item {
            display: flex;
            align-items: stretch;
            .addresss-item-inner {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                @include rounded-border;
                width: 100%;
            }
        }
        .address-footer {
            margin-top: .5rem;
            padding-top: 1rem;
            border-top: solid 1px $gray-light-color;
        }
    }
    .checkout-process {
        label {
            margin-bottom: 0;
            font-size: $font-size-small-title;
        }
        .small {
            font-size: 80%;
            font-weight: 400;
            line-height: 1;
        }
        .checkout-step {
            border: 1px solid $gray-light-color;
            padding: 1rem;
            margin-bottom: .5rem;
            margin-top: .5rem;
            &.-unreacheable {
                display: none;
            }
            &.-complete:not(.-current) {
                font-size: $font-size-small-title;
                .section-title {
                    font-size: $font-size;
                    padding: .5rem;
                    margin-bottom: 0;
                    border: none;
                    position: relative;
                    cursor: pointer;
                    &::after {
                        content: "";
                        display: inline-block;
                        margin-left: .255em;
                        vertical-align: .255em;
                        content: "";
                        border-top: .3em solid;
                        border-right: .3em solid transparent;
                        border-bottom: 0;
                        border-left: .3em solid transparent;
                        right: 1rem;
                        left: auto;
                        top: 50%;
                    }
                }
            }
        }
    }
    .logged-guest {
        display: flex;
        padding: $grid-gutter-width / 2;
        .btn {
            margin-bottom: 0;
            margin-right: 1rem;
        }
    }
    .address-selector {
        .address-item {
            .address-item-inner {
                border: 1px solid $gray-light-color;
                padding: $spacer * 2;
                font-size: $font-size-small-title;
                header.h4 {
                    font-size: $font-size;
                    border-bottom: 1px solid $gray-light-color;
                    padding-bottom: $spacer;
                    margin-bottom: $spacer;
                }
                footer {
                    display: flex;
                    justify-content:center;
                    border-top: 1px solid $gray-light-color;
                    margin-top: 1rem;
                    a {
                        padding: .3rem .8rem;
                    }
                    svg {
                        width: .75rem;
                        height: auto;
                    }
                }
            }
        }
    }
    .delivery-options-list {
        .delivery-options {
            >div {
            }
            .delivery-option {
                //                padding: 1rem;
                background: var(--light);
                margin-top: $spacer;
                margin-bottom: $spacer;
                .logo img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    #conditions-to-approve {
        margin-top: .5rem;
        ul {
            padding-left: 0;
            li {
                list-style: none;
            }
        }
    }
    .delivery-options {
        .delivery-option {
            @include rounded-border;
        }
        .carrier-logo {
            img {
                max-width: 100%;
            }
        }
    }
    .order-options {
        #delivery_message {
            width: 100%;
            @include rounded-border;
        }
    }
    .payment-options {
        .payment-item {
            @include rounded-border;
            margin-top: 1rem;
            margin-bottom: 1rem;
            .additional-information {
                font-size: 85%;
            }
        }
        .payment-option-container {
            border: 1px solid $gray-light-color;
            padding: $spacer * 2;
            margin-bottom: .2rem;
            margin-top: .2rem;
            font-size: $font-size-small-title;
            .js-additional-information {
                margin-top: 1rem;
                h3 {
                    font-size: $font-size;
                }
                p {
                    font-size: $font-size-small-title;
                }
            }
        }
    }
    #order-summary-content {
        margin-top: 1rem;
        font-size: $font-size;
        .h4 {
            font-size: $font-size;
            &.black {
                margin-top: 1rem;
                margin-bottom: 1rem;
            }
        }
        .step-edit {
            display: none;
        }
        .summary-selected-carrier {
            font-size: $font-size-small-title;
            .row {
                background: $gray-light-color;
                padding-top: .5rem;
                padding-bottom: .5rem;
            }
        }
        #order-items {
            font-size: $font-size-small-title;
            .table-summary {
                text-align: right;
                font-weight: 500;
            }
            .table-divider {
                border: 1px solid $body-background-color;
                border-bottom: 2px solid $gray-color;
            }
        }
    }
    #payment-confirmation {
        .additional-info {
            font-size: $font-size-small-title;
        }
    }
}


.order-confirmation-block {
    .card-title {
        font-size: $font-size-normal;
    }
    .order-confirmation-block-title {
        font-size: $font-size-large;
    }
    .image {
        img {
            max-width: 100%;
            height: auto;
        }
    }
    > h4 {
        font-size: $font-size-normal;
    }
}
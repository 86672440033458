.linkblock-module {
    h3 {
        font-size: 1rem;
    }
    ul {
        list-style: none;
        padding-left: 0;
    }
    li {
        line-height: 1.1;
        margin-bottom: .5rem;
        margin-top: .5rem;
        text-transform: capitalize;
        a {
            color: inherit;
            text-decoration: none;
        }
    }
}

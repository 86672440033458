.subcategories {
    ul {
        padding-left: 0;
        list-style: none;
    }
    .category-miniature-container {
        display: flex;
        margin-bottom: $grid-gutter-width / 2;
        margin-top: $grid-gutter-width / 2;
    }
    .category-miniature {
        border: 1px solid $gray-light-color;
        padding: $grid-gutter-width / 2;
        width: 100%;
        .h2 {
            font-size: $font-size-small-title;
            text-align: center;
            font-weight: 300;
            text-transform: uppercase;
            margin-top: .5rem;
        }
        a {
            color: inherit;
            text-decoration: none;
        }
    }
}
.service-top-category {
    & > h3 {
        font-size: $font-size;
    }
}
.service-category-list {
    list-style: none;
    padding: 0;
    li {
        display: flex;
    }
    figure {
        text-align: center;
        border: solid 1px var(--orange);
        display: flex;
        flex-direction: column;
        line-height: 1.1;
        padding: $spacer;
        width: 100%;
    }
    figcaption {
        //        margin-top: auto;
        margin-top: $spacer;
        font-size: $font-size-small-title;
    }
}



.category-tree {
    border: 1px solid $gray-light-color;
    a {
        text-decoration: none;
        color: inherit;
        font-weight: 300;
    }
    .category-tree-title {
        font-size: $font-size;
        border-bottom: 2px solid $red;
        padding: .5rem $grid-gutter-width / 2;
    }
    > ul {
        font
        ul {
            margin-left: $grid-gutter-width / 2;
        }
    }
    .nav-tree {
        padding-left: 0;
        .nav-item:first-child {
            border: none;
        }
    }
    .nav {
        flex-direction: column;
    }
    .nav-item {
        list-style: none;
        //        display: flex;
        position: relative;
        /*        &:first-child {
                    .item-title {
                        border: none;
                    }
                }*/
    }
    .nav-tree-item-inner {
        width: 100%;
    }

    .item-title {
        //        display: flex;
        //        flex-direction: row-reverse;
        font-size: $font-size-small-title;
        border-bottom: 1px solid $gray-light-color;
        padding: $grid-gutter-width / 2;
        ;
        margin: 0;
        a {
            font-weight: 300;
            color: $gray-dark-color;
        }
    }
    .level-0 {
        > .nav-item {
            > .item-title {
                padding-right: 1.4rem;
                > a {
                    font-weight: 400;
                    color: $black;
                }
            }
            &:last-child {
                .item-title {
                    border: none;
                }
            }
        }
    }
    .level-1 {
        > .nav-item {
            .item-title {
                padding-left: 1.2rem;
                position: relative;
                ::before {
                    position: absolute;
                    display: block;
                    content: "";
                    vertical-align: -.125em;
                    background-repeat: no-repeat;
                    background-size: 1rem 1rem;
                    left: .2rem;
                    top: .5rem;
                    width: 16px;
                    height: 16px;
                    background-position: center center;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-right' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
                    //                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-caret-right' viewBox='0 0 16 16'%3E%3Cpath d='M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z'/%3E%3C/svg%3E");
                }
            }
        }
    }
    @for $i from 1 through 5 {
        .level-#{$i} > .nav-item .item-title {
            padding-left: $i * .5rem + 1.2rem;
            ::before {
                left: $i * .5rem + .2rem;
            }
        }
    }
    .collapsed + a {
        font-weight: 300;
    }
    .nav-link {
        white-space: normal;
    }
    .accordion-header {
        font-size: $font-size;
        padding: 0 1.25rem;
        display: flex;
        align-items: center;
        font-weight: 400;

    }
    .accordion-button {
        &:not(.collapsed) {
            background-color: white;
            box-shadow: none;
        }
        &:focus {
            border: none;
            box-shadow: none;
        }
    }
    .subtree-item {
        display: block;
        text-decoration: none;
        font-size: .85rem;
        padding: .3rem .5rem;
        line-height: 1.1;
    }
    .grower {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-dash' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center .5rem;
        width: 1.4rem;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        display: block;
        z-index: 9;
    }
    .grower.collapsed {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus' viewBox='0 0 16 16'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E");
    }
    .grower:not(.collapsed) + .nav-tree-item-inner {
        font-weight: 700;
    }
}
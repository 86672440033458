
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&subset=latin-ext');
// @import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import "custom";
@import "~bootstrap/scss/bootstrap";
// @import "elements/imagelightbox";
@import "~magnific-popup/src/css/main";
//@import "libs/jquery.bootstrap-touchspin";
@import "libs/icons";


body {
    background: $body-background-color;
    font-family: 'Roboto', sans-serif;
    font-size: $font-size;
    font-weight: 300;
}
pre {
    font-size: .85rem;
}
#header {
    background: $header-color;
    //    margin-bottom: 1rem;
    border-bottom: 1px solid $form-control-border;
    .header-nav {
        display: flex;
        justify-content: flex-end;
        & > div {
            margin: .5rem 1rem;
        }
    }
}
body:not(.page-index) {
    #header {
        margin-bottom: 1rem;
    }
}
.homeslider-container {
    @extend .container-fluid;
    margin-bottom: 1rem;
}
#wrapper {

}
.banner {
    img {
        @extend .img-fluid;
    }
}
.page-title, .section-title {
    text-align: center;
    background: $gray-color;
    color: $white;
    padding: 1rem;
    margin-bottom: 4rem;
    font-size: $font-size-large;
}
.section-title {
    margin-top: 2rem;
    margin-bottom: 1rem;
}
.btn-primary {
    color: $white;
    svg {
        height: 2rem;
        text-transform: uppercase;
        fill: $white;
    }
}
.d-icon {
    max-width: $icon-size;
    height: auto;
}
.d-icon-link {
    display: flex;
    padding: .4rem;
    background: $gray-dark-color;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    justify-content: center;
    align-items: center;
    margin-right: .5rem;
    margin-left: .5rem;
    img {
        height: auto;
        width: 1.5rem;
    }
}
#imagelightbox {
    position: fixed;
    top: 350px;
    z-index: 9999;
    touch-action: none;
    box-shadow: 0 0 3.125em rgba( 0, 0, 0, .75 );
    width: auto !important;
    height: auto !important;
    max-width: 100%;
}
.notification {
    margin-top: 1rem;
    ul {
        margin-bottom: 0;
    }
}

.order-confirmation-block {
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid $gray-light-color;
    .order-confirmation-block-title {
        font-size: $font-size;
        padding-top: .5rem;
    }
}

.password-form,
.register-form {
    .form-footer {
        margin-bottom: 2rem;
    }
}
.input-group-append {
    .btn {
        line-height: 1.3;
        svg {
            height: 1rem;
        }
    }
    .btn-outline-secondary {
        border-color: $border-color;
    }
    i.eye-icon:before {
        content: url('data:image/svg+xml;charset=UTF-8, <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="eye" class="svg-inline--fa fa-eye fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"></path></svg>');
        background-size: 28px 28px;
        height: 1rem;
        width: 1rem;
        display: block;
    }
    .show i.eye-icon:before {
        content: url('data:image/svg+xml;charset=UTF-8, <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="eye" class="svg-inline--fa fa-eye fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"></path></svg>');
    }
    .hide i.eye-icon:before {
        content: url('data:image/svg+xml;charset=UTF-8, <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="eye-slash" class="svg-inline--fa fa-eye-slash fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z"></path></svg>');
    }

}

.input-group .ui-helper-hidden-accessible + .ui-autocomplete-input {
    margin-left: -1px;
    border-top-left-radius: $input-border-radius !important;
    border-bottom-left-radius: $input-border-radius !important;
}

.table {
    border-color: $gray-color;
}

.input-group {
    .btn-outline-secondary {
        border-color: $form-control-border;
    }
}

.form-check-label {
    em {
        font-size: 85%;
    }
}

table {
    .no-border {
        td {
            border: none;
        }
    }
}

@import "pages/page-maintenance";
@import "pages/page-product";
@import "pages/page-category";
@import "pages/cart";
@import "pages/my-account";
@import "pages/page-checkout";
@import "pages/page-search";
@import "pages/page-order-detail";
@import "pages/page-cms";
@import "pages/page-confirmation";

@import "elements/header";
@import "elements/footer";
@import "elements/product-list";
@import "elements/product-miniature";
@import "elements/categories";
@import "elements/breadcrumb";
@import "elements/checkout";
@import "elements/progress_steps";
@import "elements/facet";
@import "elements/notifications";

@import "modules/shoppingcart";
@import "modules/featuredproducts";
@import "modules/search-widget";
@import "modules/customersignin";
@import "modules/jms-html";
@import "modules/linkblock";
@import "modules/html-blocks";
@import "modules/payu";

.featuredproducts {
    margin-top: 3rem;
    > h3 {
        font-weight: 300;
        font-size: 2rem;
        color: $gray-color;
        text-transform: uppercase;
    }
}
.bestsellers:first-child {
    margin-top: -2rem;
}
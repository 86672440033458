.page-product {
    #main {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .product-title {
        font-size: $font-size-medium;
        margin-bottom: 1rem;
    }
    #carouselProductImages {
        margin-top: 2rem;
        margin-left: 2rem;
        margin-right: 2rem;
        .carousel-item-inner {
            display: flex;
            .carousel-item-img {
                flex: 0 1 auto;
                margin: 3px;
                cursor: pointer;
                width: 25%;
                img {
                    border: 1px solid $gray-color;
                    max-width: 100%;
                    height: auto;
                    width: 100%;
                }
            }
        }
        .carousel-control-prev, .carousel-control-next {
            width: 2rem;
        }
        .carousel-control-prev {
            left: -2rem;
        }
        .carousel-control-next {
            right: -2rem;
        }
    }
    #product-availability-descr {
        font-weight: 500;
        .label {
            font-weight: 300;
            display: inline-block;
            padding-right: 1rem;
        }
        svg {
            height: 1.75rem;
            transform: translateY(-5px);
        }
        .product-availability-result {
            display: inline-block;
            vertical-align: top;
            line-height: 1;
        }
    }
    .product-availability-info {
        font-size: $font-size-normal;
    }
    .product-price {
        margin-bottom: 0;
        margin-top: 2rem;
        white-space: nowrap;
        .main-price {
            font-size: 2.5rem;
            font-weight: 400;
        }
    }
    #descriptionTabs {
        font-size: $font-size-small-title;
    }
    #descriptionTabsContent {
        .tab-pane {
            padding: 2rem 1rem 1rem;
            margin-bottom: 1rem;
            font-size: $font-size;
            border-bottom: $border;
            h4 {
                font-size: $font-size;
            }
        }
    }
    #product-description-short {
    }
    #product-features {
    }
    .product-add-to-cart {
        p {
            margin-bottom: 0;
        }
        .btn {
            svg {
                height: 1.4rem;
            }
        }
    }
    #product-description {
        margin-top: 2rem;
        padding-top: 2rem;
        font-size: $font-size;
        .row {
            margin-bottom: 4rem;
        }
        /*        .row:nth-child(even) {
                    > div:nth-child(2) {
                        @include media-breakpoint-up(md) {
                            order: -1;
                        }
                    }
                }*/
        .zdjecie {
            display: flex;
            flex-direction: column;
            justify-content: center;
            img {
                max-width: 100%;
                height: auto;
            }
        }
        .tekst {
            display: flex;
            flex-direction: column;
            justify-content: center;
            dl {
                display: flex;
                flex-wrap: wrap;
                font-size: $font-size-small-title;
            }
            dt {
                flex-basis: 40%;
                font-weight: 300;
                &:after {
                    content: ":";
                }
            }
            dd {
                flex-basis: 60%;
            }
            /*        dd:after  {
                        content: "";
                        display: block;
                    }*/
        }
        .row {
            .column {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
        .description {
            @extend .row;
            .item-6 {
                @extend .col-md-6;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            img {
                max-width: 100%;
                height: auto;
            }
        }
        table {
            caption {
                caption-side: top;
                padding-bottom: 0;
                font-size: .75rem;
            }
        }
    }
    .product-accessories {
        margin-top: 3rem;
        //        height: 100%;
        .product-accessory-miniature {
            display: flex;
        }
        .block-title {
            font-size: $font-size;
            margin-bottom: $spacer;
        }
        .inner-block {
            border: 1px solid var(--orange);
            padding: $spacer * 2;
            display: flex;
            flex-direction: column;
            .miniature-product-title {
                font-size: $font-size-small-title;
            }
        }
        .bottom {
            background: var(--dark);
            font-size: $font-size-small;
            color: $white;
            padding: $grid-gutter-width;
            display: flex;
            justify-content: space-between;
            margin: -$spacer * 2;
            margin-top: auto;
            a {
                padding: 0;
            }
            img {
                width: 1.3rem;
            }
        }
    }
    .product-attachments {
        h3 {
            font-size: $font-size-small-title;
        }
        h4 {
            font-size: $font-size-small-title;
        }
    }
    .carrier-info {
        padding: .5rem;
        border: 1px solid $gray-light-color;
    }
    .product-variants {
        .input-container {
            label {
                width: $font-size * 1.5;
                height: $font-size * 1.5;
                display: block;
                margin: 2px;
                border: 1px solid $black;
            }
            .input-color:checked + label {
                // margin: 0;
                border: 3px solid $form-control-border;
            }
        }
        .color-select {
            list-style: none;
            padding-left: 0;
            display: flex;
        }
        .input-color {
            display: none;
        }
        .input-color + label {
            cursor: pointer;
            display: block;
        }
        .input-color:checked + label {
            color: #f00;
            font-style: normal;
        }
    }
    .product-pack {
        margin-top: 2rem;
        font-size: $font-size-small-title;
        img {
            max-width: 80px;
        }
        h2.block-title {
            font-size: $font-size;
        }
        .pack-item {
            display: flex;
            padding-top: 0;
            padding-bottom: 0;
            align-items: center;
        }
        .pack-name {
            flex-grow: 1;
            padding-left: $spacer * 2;
        }
        .pack-quantity {
            border-left: $border;
            width: 3rem;
            display: block;
            padding-left: $spacer;
            text-align: right;
        }
    }
}


#footer {
    background: $gray-dark-color;
    //    margin-bottom: 200px;
    padding-top: 2rem;
    color: $white;
    font-size: $font-size-small-title;
    h3 {
        font-size: $font-size;
    }
    .copyright {
        padding-top: 2rem;
        padding-bottom: 1rem;
        font-size: $font-size-small-title;
        text-align: right;
        margin-bottom: 0;
        a {
            color: $gray-light-color;
        }
    }
}

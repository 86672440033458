$gray-light-color: #acacac;
$gray-color: #7e7e7e;
$gray-dark-color: #3c3c3c;
$black: #000;
$white: #fff;

$form-control-border: #ced4da;

$red: #f44051;
$dark-red: #87232d;

$primary: $red;
$theme-colors: (
"primary":    $primary,
"info":    $form-control-border,
"secondary": $gray-color,
"danger": $dark-red
);



$body-background-color: $white;
$header-color: $white;
$border-color: #0f0;



$font-size: 1rem;
$font-size-normal: 1rem;
$font-size-medium: 1rem;
$font-size-small: .75rem;
$font-size-large: 1.25rem;
$font-size-small-title: .85rem;

$icon-size: 16px;

$border: 1px solid $form-control-border;


$enable-rounded: true;
$spacer: 1rem;
$grid-gutter-width: 16px;

$table-border-color: $gray-light-color;

@mixin rounded-border($radius-enebled: true, $padding-enebled: true, $b-color: $gray-light-color) {
    @if $radius-enebled {
        border-radius: $border-radius;
    }
    @if $padding-enebled {
        padding: calc(var(--bs-gutter-x) * .5);
    }
    border: solid 1px $b-color;
}
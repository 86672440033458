.page-category {
    $aside-space: 12rem;
    .category-title {
        text-align: center;
        padding: 1rem;
        margin-bottom: 4rem;
        font-size: 2rem;
        //        @include border-arrow(bottom, .1rem, 1rem);
    }
    /*    .product-miniature-category-list {
            margin-bottom: 4rem;
            //        @include border-arrow(bottom, 1rem, 1rem);
            .product-category-description {
                margin-bottom: 2rem;
                @include media-breakpoint-up(md) {
                    padding-left: 0;
                    padding-right: $aside-space;
                }
                h1 {
                    font-size: 1.85rem;
                    margin-bottom: 1rem;
                }
                .price {
                    padding-top: 1rem;
                    .price-number {
                        font-size: 1.5rem;
                        font-weight: 400;
                    }
                }
            }
            &:nth-child(odd) {
                .product-category-description {
                    @include media-breakpoint-up(md) {
                        order: -1;
                        padding-left: $aside-space;
                        padding-right: 0;
                    }
                }
            }
            &:last-child {
                border: none;
                &:after {
                    content: none;
                }
            }
        }
        .product-mminiature-bottom {
            display: flex;
            background: $header-color;
            margin-top: auto;
            justify-content: space-between;
            .btn {
                border-radius: 50%;
                width: 3rem;
                height: 3rem;
                justify-content: center;
                align-items: center;
                display: flex;
            }
            .product-price-and-shipping {
                font-size: $font-size;
                font-weight: 300;
                .price {
                    padding-top: 0;
                    font-size: $font-size-medium;
                    font-weight: 400;
                    padding-left: 1rem;
                    display: inline-block;
                }
            }
            //        flex-wrap: wrap;
        }
        .product-price-and-shipping {
            display: flex;
            align-items: baseline;
            color: $body-background-color;
            font-weight: 500;
            padding-top: .5rem;
            padding-left: 2rem;
            flex-wrap: wrap;
            flex: 1 1 auto;
        }
        .product-list-actions {
            background-image: url(../img/product-miniature-background.svg);
            background-position: center left;
            background-repeat: no-repeat;
            padding: 8px 10px 8px 35px;
            margin-left: -15px;
            background-size: contain;
            display: flex;
            .cart-add {
                margin-left: .5rem;
            }
        }*/
    #category-description {
        img {
            max-width: 100%;
            height: auto;
        }
    }
    .service-category-product-list {
        .d-icon-link {
            width: 2rem;
            height: 2rem;
            img {
                /*                    max-width: 1rem;
                                    height: auto;*/
            }
        }
    }
}